
























import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Product, SelectOption } from "@/types/typeDefinitions";
import { useProductAPI } from "@/api_connectors/product";
import UploadToast from "@/components/Toast/UploadToast.vue";
import { useVoucherAPI } from "@/api_connectors/voucher";
import { useToast } from "@/composables/useToast";
import CreateVoucher from "@/components/Modal/CreateVoucher.vue";

export default defineComponent({
    name: "VoucherImport",
    components: { CreateVoucher, UploadToast },
    setup() {
        const { getProductList } = useProductAPI()
        const { importVoucherList } = useVoucherAPI()
        const { showToast } = useToast()

        const productList = reactive<Product[]>([])
        const selectedProduct = ref<string>()
        const selectedVariant = ref<string>()
        const file = ref<HTMLInputElement>()
        const products = [{ value: '', text: '' }];
        const variantOptions = reactive<SelectOption[]>([{ value: '', text: '' }])
        const successUpload = ref(false)
        const licenseId = ref('')
        const uploadCounter = ref(0)

        const update = async () => {
            productList.splice(0)
            Object.assign(productList, (await getProductList()))

            products.splice(0)
            for (const productEL of productList) {
                if (productEL.name === '') {
                    products.push({ value: productEL.productId, text: 'No Name' })
                } else {
                    products.push({ value: productEL.productId, text: productEL.name })
                }
            }
            selectedProduct.value = products[0]?.value
        }

        const uploadVoucherExcel = (): void => {
            // console.log(file.value)
            if (!(file.value?.files?.item(0))) {
                successUpload.value = false
                return showToast('upload-toast');
            }
            const reader = new FileReader();

            reader.onload = async (readResult) => {
                if (!readResult?.target) return;

                const data = (readResult.target.result as string).split(',')[1];
                if (selectedProduct.value && selectedVariant.value) {
                    const response = await importVoucherList(selectedProduct.value, selectedVariant.value, licenseId.value, data)

                    if (response) {
                        successUpload.value = true;
                        uploadCounter.value = response?.data
                    }
                    showToast('upload-toast')
                }
            }

            const item = file.value?.files?.item(0)
            if (!item) {
                successUpload.value = false
                return showToast('upload-toast')
            }
            reader.readAsDataURL(item)
        }

        const setVariants = () => {
            if (productList.length === 0) return [{ value: '', text: '' }]
            const variants = productList.find(product => product.productId === selectedProduct.value)?.variants
            if (!variants) return Array<SelectOption>();
            const variantOption = Array<SelectOption>();
            for (const variant of variants) {
                variantOption.push({ value: variant.id, text: variant.name })
            }
            variantOptions.splice(0);
            Object.assign(variantOptions, variantOption)
        }

        onMounted(() => update())

        watch(selectedProduct, () => {
            setVariants()
            selectedVariant.value = variantOptions[0]?.value
        })

        return {
            productList,
            selectedProduct,
            selectedVariant,
            products,
            variantOptions,
            successUpload,
            licenseId,
            uploadCounter,
            file,
            uploadVoucherExcel
        }
    }
})
