import { render, staticRenderFns } from "./VoucherImport.vue?vue&type=template&id=1545c80c&scoped=true&"
import script from "./VoucherImport.vue?vue&type=script&lang=ts&"
export * from "./VoucherImport.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1545c80c",
  null
  
)

export default component.exports