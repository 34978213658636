




























































































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { Product, SelectOption, Voucher } from "@/types/typeDefinitions";
import { useEmptyObject } from "@/types/newEmptyObject";
import { useProductAPI } from "@/api_connectors/product";
import { useVoucherAPI } from "@/api_connectors/voucher";

export default defineComponent({
    name: "CreateVoucher",
    setup() {
        const { newVoucher } = useEmptyObject()
        const { getProductList } = useProductAPI()
        const { generateVoucherKey, createVoucherAPI } = useVoucherAPI()

        const voucher = ref<Voucher>(newVoucher())
        const products = reactive<SelectOption[]>([])
        const productList = reactive<Product[]>([])
        const variants = reactive<SelectOption[]>([])

        const update = async () => {
            products.splice(0);
            productList.splice(0);
            (await getProductList()).forEach(productEl => {
                productList.push(productEl)
                products.push({ value: productEl.productId, text: productEl.name })
            })
        }

        const setVariants = () => {
            variants.splice(0)
            if (voucher.value.productId) {
                const product = productList.find(product => product.productId === voucher.value.productId)
                if (!product) return

                for (const variant of product.variants) {
                    variants.push({ value: variant.id, text: variant.name })
                }
            }
        }

        const createVoucher = async (closeModal: Function) => {
            await createVoucherAPI(voucher.value)

            resetObject()
            closeModal()
        }

        const generateVoucher = async () => {
            voucher.value.key = await generateVoucherKey()
        }

        const resetObject = () => {
            voucher.value = newVoucher()
        }

        watch(() => voucher.value.productId, setVariants)

        onMounted(update)

        return {
            voucher,
            products,
            productList,
            variants,
            generateVoucher,
            createVoucher,
            resetObject
        }
    }
})
